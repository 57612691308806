@import '../module-variables';

.title {
    font-size: 18px;
    font-weight: 800;
    color: $hex-404040;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.bookingMenuLink {
    // {`user-select-none text-uppercase fs-16px fw-600 c-pointer py-2 px-3 rounded bg-${props.active ? 'ec3b50 text-white' : 'ffffff text-404040'} border shadow-sm`}
    user-select: none;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: $border-radius;
    background: $hex-ffffff;
    color: $hex-404040;
    border: 1px solid $hex-dadada;
    box-shadow: $box-shadow-sm;

    &.active {
        background: $hex-ec3b50;
        color: $hex-ffffff;
    }
}