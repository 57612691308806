@import '../module-variables';

.container {
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 900;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}