.uploadContainer {
    width: 60%;
    margin-left: 0;
    margin-bottom: 20px;
    border: 1px dashed #ccc;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.uploadLabel {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
}

.uploadInput {
    display: none;
}

.selectedFile {
    margin-top: 8px;
    font-size: 14px;
}

.fileFormat {
    display: block;
    margin-top: 8px;
    color: #666;
    font-size: 12px;
}