@import '../module-variables';

.sideNavbar {
    position: sticky;
    top: 0%;
    left: 0%;
    height: 100vh;
    z-index: 300;
    box-shadow: $box-shadow-sm;
    width: 250px;
    background: $hex-111827;
    padding: 8px 12px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logoWrapper {
            height: 55px;
            width: 140px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .logoCaption {
        margin-top: 2px;
        font-size: 12px;
        color: $hex-929292;
        font-weight: 600;
    }

    .shopName {
        color: $hex-ffffff;
        text-transform: uppercase;
        margin: 8px 0px;
        font-size: 16px;
        font-weight: bold;
    }

    .itemWrapper {
        border-radius: $border-radius;
        overflow: hidden;

        &:hover {
            background: $hex-29303d !important;
        }

        &.active {
            background: $hex-29303d;
        }

        &.inactive {
            background: $hex-111827;
        }

        .item {
            width: 100%;
            text-decoration: none;
            padding: 0px 12px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-self: stretch;
            border-radius: $border-radius;
            cursor: pointer;

            
            &.buttonStyle{
                background: $hex-ff5050;
                border-radius: 18px;
                top: 15%;
                height: 35px;
                max-width: fit-content;
                box-shadow: $box-shadow-sm;
            }

            .left {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
            }

            .right {
                width: 5px;
                height: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: transform 0.2s linear;

                &.collapsed {
                    transform: rotate(90deg);
                    transition: transform 0.2s linear;
                }
            }
        }

        .innerItem {
            padding-left: 30px;
            overflow: hidden;
            transition: all 0.2s linear;
            cursor: pointer;
        }

        .label {
            font-size: 14px;
            line-height: 40px;
            color: $hex-ffffff;
            text-transform: capitalize;
        }
        .version {
            font-size: 12px;
            color: $hex-ffffff;
            text-transform: capitalize;
            
        }
    }
 
    &.fixed {
        position: fixed;
        transition: margin-left 0.1s linear;

        &.active {
            margin-left: 0px;
            transition: margin-left 0.1s linear;
        }

        &.inactive {
            margin-left: -260px;
            transition: margin-left 0.1s linear;
        }
    }

    &.light {
        background: $hex-ffffff;

        .itemWrapper {
            &:hover {
                background: $hex-e0e0e0 !important;
            }

            &.active {
                background: $hex-e0e0e0;
            }

            &.inactive {
                background: $hex-ffffff;
            }

            .label {
                color: $hex-404040;
                font-weight: 600;
            }
        }
    }
}