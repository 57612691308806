@import '../module-variables';


.card {
    width: 100%;
    border-radius: 24px;
    background: $hex-ffffff;
    margin-bottom: 15px;
  
    .lable{
        font-weight: 200;
    }
}



    
  