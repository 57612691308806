@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'variables';
@import 'fonts.scss';
@import 'swiper.scss';

*,
html,
body {
    margin: 0;
    font-family: $font-family, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
select {
    outline: none;
}

select {
    appearance: none;
}

textarea {
    outline: none;
    resize: none;
}

.table-wrapper{
overflow: auto;
max-height: calc(100vh - 200px);
}