@import '../module-variables';

.container {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.line1 {
    border: 1px solid $hex-404040;
    background: $hex-404040;
    border-radius: 25px;
    height: 25px;
    transform: rotate(45deg);

    &.small {
        height: 12px;
    }

    &.white {
        border: 1px solid $hex-ffffff;
        background: $hex-ffffff;
    }
}

.line2 {
    @extend .line1;
    transform: rotate(-45deg);
    margin-left: -2px;
}