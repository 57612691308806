@import '../module-variables';

.signInContainer {
    max-width: 580px;
    border-radius: 24px;
    background: $hex-ffffff;
    width: calc(100% - 20px);
}

.registerContainer {
    @extend .signInContainer;
    max-width: 1000px;
}

.logoContainer {
    width: 141px;
    height: 55px;

    img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
    }
}

.logoCaption {
    font-size: 12px;
    color: $hex-929292;
    font-weight: 600;
}

.headerText {
    font-size: 18px;
    color: $hex-404040;
    font-weight: 800;
    text-transform: uppercase;
    text-align: start;
}

.mainContent {
    overflow: auto;
    max-height: calc(100vh - 340px);
}

.switchLink {
    margin-top: 18px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    user-select: none;

    &.userAuthText{
        text-decoration:underline;
        font-weight: 600;
    }

    &:hover {
        text-decoration: underline;
    }
}

.forgotPassword {
    font-size: 14px;
    font-weight: 600;
    color: $hex-404040;
    margin-bottom: 3px;
    display: inline-block;
    max-width: 100%;
    cursor: pointer;
    text-decoration: none;
    @include text-truncate();

    &:hover {
        text-decoration: underline;
    }
}

.stateContainer {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.pincodeContainer {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.userPincodeContainer {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.link {
    color: $hex-404040;
    font-size: 14px;
    font-weight: 600;

    &:hover {
        color: $hex-404040;
    }
}

.required{
    color: red;
}