@import '../module-variables';

.title {
    font-size: 18px;
    font-weight: 800;
    color: $hex-404040;
    text-transform: uppercase;
}

.button {
    background: $hex-2ac8fe;
    color: $hex-ffffff;
    text-transform: capitalize;
}

.icon {
    cursor: pointer;
}