@import '../module-variables';

.trash {
    z-index: -1;
    opacity: 0;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: $hex-ec3b50;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cell {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $hex-dadada;
    margin: 8px;
    border-radius: $border-radius;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: $hex-ffffff;
    user-select: none;

    &:hover .trash {
        z-index: 1;
        opacity: 1;
        transition: all 0.2s linear;
        border: 1px solid $hex-ec3b50;
    }
}