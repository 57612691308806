@import '../module-variables';

.categoryTitleContainer {
    width: 100%;
    margin-bottom: 12px;
    scroll-margin-top: 70px;

    .categoryTitle {
        font-size: 14px;
        color: $hex-404040;
        text-decoration: none;

        &:hover {
            color: $hex-404040;
        }

        @include media-breakpoint-up(md) {
            font-weight: 800;
            margin-bottom: 18px;
        }
    }
}


.categorySectionContainer {
    border-right: 1px solid $hex-dadada;
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    .categorySectionContent {
        width: 100%;
        position: sticky;
        top: 0%;
        overflow: auto;
        height: calc(100vh - 100px);

        .categoriesTitle {
            font-size: 16px;
            font-weight: 800;
            color: $hex-404040;
            text-transform: uppercase;
            margin-bottom: 24px;
        }

        .categoryListItem {
            display: block;
            margin-bottom: 8px;

            a {
                font-size: 14px;
                text-decoration: none;
                font-weight: 500;
                color: $hex-404040;
                cursor: pointer;
                user-select: none;

                &.active {
                    font-weight: 800;
                    color: $hex-2ac8fe;
                }
            }
        }
    }

}

.searchInputContainer {
    padding-bottom: 18px;
    position: sticky;
    top: 0%;
    background: $hex-ffffff;
    z-index: 100;
}

.foodSectionContent {
    margin-bottom: 24px;

    &.smallScreen {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &.largeScreen {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}