@import '../module-variables';

.menuIcon {
    cursor: pointer;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.logoWrapper {
    width: 140px;
    height: 55px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.logoCaption {
    display: none;
    font-size: 12px;
    color: $hex-929292;
    font-weight: 600;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}

.cartIcon {
    cursor: pointer;
    position: relative;

    @include media-breakpoint-up(md) {
        display: none;
    }

    .cartSize {
        position: absolute;
        bottom: 30%;
        color: $hex-2ac8fe;
        font-weight: 600;
        font-size: 14px;
        transform: translateX(100%);
        text-align: center;
    }
}

.searchContainer {
    position: relative;

    .searchResultContainer {
        width: 100%;
        background: $hex-ffffff;
        border: 1px solid $hex-dadada;
        box-shadow: $box-shadow-sm;

        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 200;

        .searchResultItem {
            padding: 8px;
            border-bottom: $border-width $border-style $border-color;
            cursor: pointer;
            user-select: none;
            display: block;
            text-decoration: none;

            .imageWrapper {
                width: 40px;
                height: 40px;
                border-radius: $border-radius;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                &.noImage {
                    background: $hex-e0e0e0;

                    img {
                        display: none;
                    }
                }
            }

            &.info {
                &:hover {
                    background: $hex-ffffff;

                    .name {
                        color: $hex-404040;
                    }

                    .subName {
                        color: $hex-929292;
                    }
                }
            }

            .name {
                font-size: 14px;
                color: $hex-404040;
                font-weight: 600;
            }

            .subName {
                font-size: 14px;
                color: $hex-929292;
                font-weight: 400;
            }

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background: $hex-e0e0e0;

                .name {
                    color: $hex-404040;
                }

                .subName {
                    color: $hex-929292;
                }
            }
        }
    }
}