@import '../module-variables';

.homeContainer {
    // 'max-w-280px h-210px d-flex align-items-center justify-content-center br-16px shadow-sm overflow-hidden'
    width: 100%;
    height: 240px;
    padding: 6px;
    max-width: 400px;
    border-radius: 16px;
    box-shadow: 0px 3px 24px $hex-4040400f;
    text-decoration: none;
    border: 1px solid $hex-e0e0e0;
    align-items: center;
    justify-content: center;

    .countLabel {
        display: flex;
        color: #404040;
        padding: 20px;
        font-size: 14px;
        font-weight: 600;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;
    }

    .count {
        display: flex;
        color: #404040;
        padding: 30px 80px;
        font-size: 40px;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        @include text-truncate();
    }

    .countg {
        display: flex;
        color: orange;
        padding: 30px 80px;
        font-size: 40px;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        @include text-truncate();
    }

    .countl {
        display: flex;
        color: red;
        padding: 30px 80px;
        font-size: 40px;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        @include text-truncate();
    }

}

.cardSection {
    cursor: pointer;
}

.headerTitle {
    display: flex;
    color: #404040;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
}

.calenderCard {
    border: 1px solid #dadada;
    padding: 20px;
    border-radius: 15px;
    margin: 10px 20px;
    height: 600px;

    .listItem {
        padding: 10px;
        border: 1px solid #dadada;
        margin-bottom: 3px;
        border-radius: 15px;
    }
}