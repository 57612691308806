@import '../module-variables';

.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    gap: 10px;
    height: 100%;

    @include media-breakpoint-up(sm) {
        justify-content: end;
    }

    .trashIcon {
        padding: 0px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .counter {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border: 1px solid $hex-dadada;
        border-radius: 12px;
        padding: 0px 24px;
        height: 40px;

        .quantity {
            color: $hex-404040;
            font-size: 14px;
            font-weight: 600;
            margin: 0px 16px;
        }
    }
}