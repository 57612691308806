@import '../module-variables';

.button {
    user-select: none;
    padding: 8px 24px;
    border-radius: $border-radius;
    border: 1px solid $hex-dadada;
    box-shadow: $box-shadow;
    text-transform: capitalize;
    cursor: pointer;
    display: inline-block;
    background: $hex-2ac8fe;
    color: $hex-ffffff;
}