@import '../module-variables';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $hex-dadada;
    border-radius: $border-radius;
    overflow: hidden;
    background: $hex-ffffff;

    .name {
        padding: 8px;
        padding-right: 0px;
        height: 100%;
        width: calc(100% - 40px);
        font-weight: 600;
        font-size: 14px;
    }

    .edit {
        width: 40px;
        height: 100%;
        cursor: pointer;
        text-align: center;
        padding: 8px 0px;
    }
}