@import '../module-variables';

.container {
    position: fixed;
    top: 0%;
    right: 0%;
    max-width: 300px;
    z-index: 900;
    overflow: hidden;
    padding: 12px;

    .item {
        border-radius: $border-radius;
        margin: 8px 0px;
        box-shadow: $box-shadow-sm;
        display: flex;
        align-items: center;
        align-self: stretch;
        justify-content: space-between;
        height: 100%;
        padding: 12px;

        &.success {
            background: $hex-4ab96a;
        }

        &.error {
            background: $hex-ec3b50;
        }

        .labelWrapper {
            margin-right: 12px;
            display: flex;
            align-items: center;

            .label {
                font-size: 13px;
                color: $hex-ffffff;
            }
        }

        .closeWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .close {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}