@import '../module-variables';

.select {
    width: 100%;
    height: 48px;
    padding: 0px 16px;
    color: $hex-404040;
    border-radius: 16px;
    border: 1px solid $hex-dadada;

    &.error {
        border: 1px solid $hex-ff5050;
    }
}