@import '../module-variables';

.container {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background: $hex-00000066;
    z-index: 400;
    overflow: auto;

    &.highPriority {
        z-index: 2000;
    }

    .mainContent {
        display: flex;
        justify-content: center;
        padding: 12px;
        margin-bottom: 300px;

        @include media-breakpoint-up(sm) {
            & {
                margin-bottom: 0px;
            }
        }

        @include media-breakpoint-up(md) {
            & {
                width: 100%;
                height: 100%;
                align-items: center;
            }
        }

        &.animate {
            animation: animate 0.2s linear 1 forwards;

            @keyframes animate {
                from {
                    transform: scale(0.7);
                    opacity: 0;
                }

                to {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }
}