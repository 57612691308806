@import '../module-variables';

.container {
    border: 1px solid $hex-dadada;
    border-radius: 24px;
    padding: 24px 24px 0px;
    margin-bottom: 24px;

    .title {
        font-size: 16px;
        font-weight: 800;
        color: $hex-404040;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    .description {
        font-size: 14px;
        font-weight: 400;
        color: $hex-404040;
    }

    .registerButton {
        max-width: 176px;
        width: 100%;
        background: $hex-2ac8fe;
        color: $hex-ffffff;
        margin-bottom: 24px;

        &:hover {
            background: $hex-2ac8fe;
            color: $hex-ffffff;
        }
    }

    .imageWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
            width: 100%;
            height: 100%;
            max-width: 400px;
            object-fit: cover;
            object-position: center;
        }
    }
}