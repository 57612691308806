@import '../module-variables';

.container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: $hex-ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .loaderWrapper {
        width: 300px;
        height: 300px;
    }
}