@import '../module-variables';

.thankyou {
    font-size: 32px;
    font-weight: 600;
    color: $hex-404040;
    text-align: center;
    margin: 16px 0px;
}

.qrText{
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin: 16px 0px;
    color: $hex-63be6b;
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    from { opacity: 1.0; }
    50% { opacity: 0.2; }
    to { opacity: 1.0; }
}

.orderPlaced {
    @extend .thankyou;
    font-size: 16px;
    margin: 12px 0px;
}

.orderNumber {
    @extend .orderPlaced;
    margin: 12px 0px 16px;
}