@import '../module-variables';

.container {
    width: 100%;
    background: $hex-404040;
    border: 1px solid $hex-707070;
    padding: 30px 0px;
    position: sticky;
    top: 100%;

    .sectionHeader {
        color: $hex-ffffff;
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
    }

    .link {
        color: $hex-ffffff;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;

        .linkLabel {
            color: $hex-ffffff;
            text-decoration: none;
        }
    }

    .placeOrderOnApp {
        color: $hex-ffffff;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .button {
        width: 100%;
        max-width: 280px;
        padding: 8px 0px;
        border-radius: 12px;
        border: 1px solid $hex-ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        cursor: pointer;

        .downloadFrom {
            color: $hex-ffffff;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
        }

        .label {
            color: $hex-ffffff;
            text-align: center;
            font-size: 14px;
            font-weight: 700;
        }
    }

    .copyRight {
        color: $hex-ffffff;
        font-size: 14px;
        font-weight: 500;
        margin-top: 15px;
    }
}