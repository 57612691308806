@import '../module-variables';

.title {
    font-size: 25px;
    font-weight: 800;
    color:$hex-404040;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}
.headings{
    font-size: 15px;
    font-weight: 800;
    color:$hex-ec3b50;
    text-transform: uppercase;
    margin-bottom: 18px;
}
.subtitle{
    font-size: 20px;
    font-weight: 800;
    color:$hex-45afe3;
    text-transform: uppercase;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
}
 
