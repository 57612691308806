@import '../module-variables';

.container {
    width: 100%;

    .animationContainer {
        width: 100%;
        height: calc(100vh - 150px);
    }

    .buttonWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        .button {
            max-width: 250px;
            width: 100%;
            background: $hex-ec3b50;
            color: $hex-ffffff;
        }
    }
}