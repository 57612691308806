@import '../module-variables';

.accordion {
    .item {
        margin-bottom: 5px;
        border-radius: $border-radius;
        overflow: hidden;
        border: 1px solid $hex-dadada;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            border-bottom: 1px solid $hex-dadada;
            cursor: pointer;

            .title {
                font-weight: 800;
                font-size: 14px;
                color: $hex-404040;
                user-select: none;
            }

            .chevronWrapper {
                padding-right: 10px;

                .chevron {
                    transition: all 0.2s linear;
                }
            }
        }

        .content {
            overflow: hidden;
            transition: all 0.2s linear;
        }

        &.collapse {
            .header {
                .chevronWrapper {
                    .chevron {
                        transform: rotate(-180deg);
                        transition: all 0.2s linear;
                    }
                }
            }
        }
    }
}