@import '../module-variables';

.container {
    display: flex;
    align-items: center;
    justify-content: center;

    .spinLine {
        width: 20px;
        height: 20px;
        border: 2px solid $hex-ffffff;
        border-top: 2px solid transparent !important;
        border-radius: 50%;
        animation: spin 0.5s linear infinite forwards;

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }
}