@import '../module-variables';

.container {
    width: 100%;
    // padding: 6px;
    max-width: 400px;
    border-radius: 16px;
    // box-shadow: 0px 3px 24px #929292;
    text-decoration: none;
    // border: 1px solid $hex-e0e0e0;
    box-shadow : 0 2px 5px 0 rgba(40,44,53,.1),0 1px 20px 0 rgba(40,44,53,.1);
    border: 0;

    .imageContainer {
        position: relative;
        margin-bottom: 20px;

        .imageWrapper {
            width: 100%;
            height: 198px;
            border-radius: 16px 16px 0 0;
            overflow: hidden;

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .absoluteContainer {
            position: absolute;
            top: 100%;
            right: 0%;
            transform: translate(-16px, -50%);

            .offerLabelContent {
                border-radius: $border-radius-pill;
                border: 2px solid $hex-ffffff;
                background: $hex-fe7a5b;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                padding: 4px 8px;
            }

            .ratingContent {
                @extend .offerLabelContent;
                // background: $hex-404040;
                background: #37b6fe;
                border:2px solid #37b6fe;
            }

            .absoluteIconWrapper {
                width: 13px;
                height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .absoluteContainerLabel {
                font-size: 14px;
                font-weight: 400;
                color: $hex-ffffff;
            }
        }

        .switchStatus {
            position: absolute;
            top: 40%;
            right: 60%;
            transform: translate(-16px, -50%);
        }

        .cameraWrapper {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 40px;
            height: 40px;
            background: $hex-ffffff;
            box-shadow: 0px 6px 16px $hex-00000014;
            border-radius: 12px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            display: flex;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        .iosHeartIconWrapper {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 40px;
            height: 40px;
            background: $hex-ffffff;
            box-shadow: 0px 6px 16px $hex-00000014;
            border-radius: 12px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            display: flex;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .editIconWrapper {
            position: absolute;
            right: 10px;
            top: 55px;
            width: 40px;
            height: 40px;
            background: $hex-ffffff;
            box-shadow: 0px 6px 16px $hex-00000014;
            border-radius: 12px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            display: flex;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .castIconWrapper {
            position: absolute;
            left: 10px;
            top: 10px;
            width: 40px;
            height: 40px;
            background: $hex-ffffff;
            box-shadow: 0px 6px 16px $hex-00000014;
            border-radius: 12px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            display: flex;

            &.showCast {
                display: flex;
            }

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .facebookIconWrapper {
            position: absolute;
            left: 10px;
            top: 10px;
            width: 40px;
            height: 40px;
            background: #3b5998;
            box-shadow: 0px 6px 16px $hex-00000014;
            border-radius: 12px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            display: flex;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    &:hover {
        .iosHeartIconWrapper {
            display: flex;
        }

        .editIconWrapper {
            display: flex;
        }

        .castIconWrapper {
            display: flex;
        }

        .facebookIconWrapper {
            display: flex;
        }
    }

    .bottomSection {
        padding: 0px 10px 10px;

        .name {
            color: $hex-404040;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            @include text-truncate();
        }

        .subText {
            @extend .name;
            font-weight: 400;
            text-transform: capitalize;
        }

        .includes {
            @extend .subText;
            color: $hex-929292;
        }

        .startsFrom {
            color: $hex-929292;
            font-size: 13px;
            font-weight: 500;
            @include text-truncate();
        }

        .price {
            color: $hex-404040;
            font-size: 14px;
            font-weight: 600;
            @include text-truncate();
        }

        .offerPrice {
            color: brown;
            font-size: 14px;
            font-weight: 600;
            @include text-truncate();
        }

        .locationWrapper {
            display: flex;
            align-items: center;
            gap: 4px;

            .locationIconWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .locationPrefix {
                font-size: 14px;
                color: $hex-404040;
                font-weight: 600;
            }

            .locationSuffix {
                font-size: 14px;
                color: $hex-929292;
                font-weight: 400;
                @include text-truncate();
            }
        }

        .editButton {
            width: 100%;
            color: $hex-404040;
            background: $hex-ffffff;
            text-transform: capitalize;

            &:hover {
                color: $hex-404040;
                background: $hex-e0e0e0;
            }
        }

        .cloneButton {
            width: 100%;
            margin-top: 5px;
            color: $hex-404040;
            background: $hex-ffffff;
            text-transform: capitalize;

            &:hover {
                color: $hex-404040;
                background: $hex-e0e0e0;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        max-width: 278px;
    }
}

.editPrice {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: blue;
    }
}

.tableStyle {
    width: 145px;
}

.buttonStyle {
    width: 55px;
    height: 30px;
    color: $hex-ffffff;
    background-color: $hex-2ac8fe;
}