@import '../module-variables';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .input {
        appearance: none;
        width: 2.5rem;
        height: 1.3rem;
        background: $hex-dadada;
        border-radius: 1.3rem;
        box-shadow: inset 0px 0px 5px $hex-00000029;
        position: relative;
        transition: all 0.2s linear;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 1.3rem;
            height: 1.3rem;
            background: $hex-ffffff;
            box-shadow: 0px 0px 2px 2px $hex-00000029;
            border-radius: 50%;
            transform: scale(1.1);
            transition: all 0.2s linear;
        }

        &:checked {
            background: $hex-63be6b;
            transition: all 0.2s linear;
        }

        &:checked::after {
            left: calc(100% - 1.3rem);
            transition: all 0.2s linear;
        }
    }

    .label {
        font-size: 14px;
        font-weight: 600;
        color: $hex-404040;
        display: inline-block;
        max-width: 100%;
        @include text-truncate();
    }
}