@import '../module-variables';

.container {
    cursor: pointer;
    position: relative;
    text-decoration: none;

    .cartSize {
        position: absolute;
        width: 30px;
        height: 20px;
        top: 38%;
        left: auto;
        color: $hex-2ac8fe;
        font-weight: 600;
        font-size: 14px;
        transform: translate(0%, -50%);
        text-align: center;
    }

    .listContainer {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
        background: $hex-ffffff;
        z-index: 200;
        min-width: 100px;
        width: 168px;

        .itemLink {
            text-decoration: none;
            display: block;
            color: $hex-404040;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;

            &:hover {
                color: $hex-404040;
                background: $hex-dadada;
            }
        }
    }

    &:hover .listContainer {
        display: block;
    }

    &.buttonStyle {
        background: $hex-ff5050;
        border-radius: 16px;
        top: 15%;
        height: 35px;
        border: 1px solid $hex-dadada;
        box-shadow: $box-shadow-sm;
    }

    .listArrow {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: $hex-dadada;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:hover .listArrow {
        display: block;
    }

    .label {
        color: $hex-404040;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        @include text-truncate();

        &.textColor {
            color: $hex-ffffff;
        }
    }
}