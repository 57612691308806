@import '../module-variables';


.container{
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    box-shadow: $box-shadow-sm;
    border: 1px solid $hex-dadada;
    height: 50px;

    .card{
        padding: 0px 2px;
        .cardList{
            text-transform: uppercase;
            align-items: center;
            font-weight:500;
            padding: 0px 8px;
            height: 100%;
        }
         
        .editButton {
            border-radius: 0 $border-radius $border-radius 0;
            height: 100%;
            cursor: pointer;
            align-items: center;
            justify-content: center;
        
        }
    }
  
}