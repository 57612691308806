@import '../module-variables';

.viewContainer {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.companyName {
    font-size: 80px;
    margin: auto;
    margin: 15px 0px;
    display: inline-block;
}

.settingIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    border: none;
    background: none;
    border-radius: 10px;
    // font-family: 'EB Garamond', serif;
}

.settingIcon:hover {
    cursor: pointer;
    color: gray;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    border: 1px solid black;
    background-color: white;
    width: 50%;
    height: 90%;
    padding: 20px;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 9999;
}

.inputField {
    border: 1px solid black;
    margin: 5px auto;
}

.scrollModeText {
    font-weight: 600;
    // font-family: Arial, Helvetica, sans-serif;
    position: relative;
}

.products {
    text-align: center;
    // font-family: 'EB Garamond', serif;
}


.products > li {
    background-color: none;
    color: #292422;
    font-size: 20px;
    padding: 20px 0px;
    text-align: center;
    justify-content: center;
    list-style-type: none;
    margin: auto;
    overflow: hidden;
    /* border-radius: 20px; */
    /* box-shadow: 0px 0px 5px inset; */
    margin: -4vh -2.5vw 0vh -2.5vw;
    z-index: 99;
    // font-family: 'EB Garamond', serif;
}

.productImage {
    height: 50vh;
    max-width: 850px;
    border-radius: 50px;
}

.productName {
    font-size: 70px;
    // font-family: 'EB Garamond', serif;
}

.priceContainer{
    align-items: center;
    text-align: center;
    justify-content: center;

    .worsePrice {
        font-size: 50px;
        color: darkred;
        text-align: center;
        text-decoration: line-through;
        // font-family: 'EB Garamond', serif;
    }
    
    .bestPrice {
        margin-top: 0%;
        color: black;
        font-size: 70px;
        // font-family: 'Anton', sans-serif;
    }
}



.btn {
    margin-top: 5px;
    border: none;
    background-color: bisque;
    padding: 10px;
    cursor: pointer;
    // font-family: 'Phudu', cursive;
    border-radius: 10px;
    display: none;
}

.btn:hover {
    border: 1px solid black;
}

.rightLeftAnimations {
    animation: slideInRight 1s, pop 0.5s, slideOutLeft 1s;
    animation-delay: 0s, 2s, 4.1s;
}

.bottomTopAnimations {
    animation: slideInBottom 1s, pop 0.5s, slideOutTop 1s;
    animation-delay: 0s, 2s, 4.1s;
}

.fadeInOut {
    animation: fadeIn 1s, pop 0.5s, fadeOut 1s;
    animation-delay: 0s, 2s, 4.1s;
}

.pop {
    animation: pop 0.5s;
    animation-delay: 2s;
}

.slideInRight {
    animation: slideInRight 1s;
    animation-delay: 1s;
}

.slideOutLeft {
    animation: slideOutLeft 1s;
    animation-delay: 4.1s;
}

// .list {

// }

.logoContainer{
    position: relative;
    .logoText{
        position: absolute;
        bottom:0px;
        right: 80px;
        .vshopLogo{
            top:5px;
            width: 120px;
            height: 50px;
        }
    }
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(200%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-200%);
    }
}

@keyframes slideInBottom {
    0% {
        opacity: 0;
        transform: translateY(150%)
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOutTop {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-150%);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes pop {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.06);
    }

    100% {
        transform: scale(1);
    }
}


@media(max-width: 1000px) {
    .products > li {
        margin-left: -50px;
    }

    .productImage {
        margin-top: 1vh;
        margin-left: 0;
        padding-left: 0;
        max-height: 40vh;
        max-width: 600px;
    }

    .productName {
        font-size: 60px;
        font-family: 'EB Garamond', serif;
    }

    .worsePrice {
        font-size: 50px;
        font-family: 'EB Garamond', serif;
    }
    .bestPrice {
        font-size: 70px;
        font-family: 'EB Garamond', serif;
    }
    
}

@media(max-width: 700px) {
    .products > li {
        margin-left: -50px;
    }

    .productImage {
        margin-top: 6vh;
        margin-left: 0;
        padding-left: 0;
        max-height: 30vh;
        max-width: 300px;
    }

    .productName {
        font-size: 60px;
        font-family: 'EB Garamond', serif;
    }

    .bestPrice {
        font-size: 70px;
        font-family: 'EB Garamond', serif;
    }

    .worsePrice {
        font-size: 50px;
        font-family: 'EB Garamond', serif;
    }
}