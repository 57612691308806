@import '../module-variables';

.content {
    display: flex;

    .left {
        width: 80%;

        .name {
            color: $hex-404040;
            font-weight: 600;
            font-size: 14px;
        }

        .businessName {
            color: $hex-929292;
            font-weight: 400;
            font-size: 14px;
        }
    }

    .right {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .more {
            cursor: pointer;
            font-size: 14px;
            color: $hex-404040;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.bannerImage {
    width: 75px;
    height: 50px;
    // width: 278px;
    // height: 206px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 10px;
}

.gotoshop {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: blue;
    }
}