@import '../module-variables';

.imageWrapper {
    min-width: 90px;
    width: 90px;
    height: 90px;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.mainContent {
    width: calc(100% - 90px);

    .name {
        // 'text-404040 fs-14px fw-600 mb-1 text-truncate'
        color: $hex-404040;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
        @include text-truncate();
    }

    .editOrder {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        .label {
            color: $hex-929292;
            font-size: 14px;
            font-weight: 500;
            height: 20px;
            text-decoration: underline;
            display: inline-block;

            &:hover {
                color: $hex-929292;
            }
        }
    }

    .status {
        display: inline-block;
        padding: 0px 8px;
        color: $hex-ffffff;
        border-radius: 0.3rem;
        text-transform: capitalize;
        font-size: 12px;

        &.available {
            background: $hex-4ab96a;
        }

        &.notAvailable {
            background: $hex-ec3b50;
        }
    }

    .price {
        color: $hex-404040;
        font-size: 14px;
        font-weight: 600;
        margin-top: 2px;
        @include text-truncate()
    }
}