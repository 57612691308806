@import '../module-variables';

.container {
    width: 100%;
    max-width: 400px;
    border-radius: 16px;
    box-shadow: 0px 3px 24px $hex-4040400f;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid $hex-e0e0e0;
    padding: 16px;

    .codeAndValue {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $hex-404040;
        margin-bottom: 2px;

        .value {
            font-size: 20px;
            font-weight: 700;
            color: $hex-ec3b50;
        }

        .code {
            font-size: 18px;
            font-weight: 700;
            color: $hex-111827;
        }
    }

    .validity {
        text-transform: uppercase;
        color: $hex-707070;
        font-size: 14px;
        font-weight: 500;

        span {
            font-weight: 700;
            color: $hex-111827;
        }
    }

    .orderValue {
        color: $hex-404040;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .title {
        font-size: 14px;
        color: $hex-404040;
        font-weight: 600;
    }

    .description {
        font-size: 13px;
        color: $hex-404040;
    }

    .button {
        width: 100%;
        margin: 0px auto;
        background: $hex-ffffff;

        &:hover {
            background: $hex-dadada;
        }
    }
}