@import '../module-variables';

.container {
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    box-shadow: $box-shadow-sm;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    border: 1px solid $hex-dadada;
    height: 34px;

    .clickable {
        text-transform: capitalize;
        display: flex;
        align-items: center;
        padding: 0px 8px;
        height: 100%;
        @include text-truncate();

        &.active {
            background: $hex-ec3b50;
            color: $hex-ffffff;
        }
    }

    .editButton {
        display: flex;
        border-radius: 0 $border-radius $border-radius 0;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0px 8px;
    }

    &.error {
        border: 1px solid $hex-ff5050;
    }
}