@import '../module-variables';

.productName {
    overflow: hidden;
    font-size: 18px;
    color: $hex-404040;
    font-weight: 800;
    text-transform: uppercase;
    @include text-truncate();
}

.previousButton {
    width: 100px;
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    text-transform: capitalize;
}

.addToCartButton {
    width: 100%;
    height: 30px;
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    text-transform: capitalize;
}

.offerPrice {
    margin: auto 0;
    color: #FF215F;
}

.productContentRight {
    min-width: 270px;
    height: 100px;
    background-color: #fff;
    margin-left: auto;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 20px 12px -16px rgba(0, 30, 85, .1), 0 8px 24px 18px rgba(0, 30, 85, .05);
}

.price {
    margin: auto 10px;
    color: #8a8a8a;
    font-size: 0.9em;
    font-weight: 600;
    text-decoration: line-through;
}

.title {
    font-size: 18px;
    font-weight: 800;
    color: $hex-45afe3;
    text-transform: uppercase;
    margin-bottom: 18px;
    text-align: center;
}

.blogImageSuper {
    width: 250px;
    height: 200px;
    margin-bottom: 10px;
    border-radius: $border-radius;
    overflow: hidden;
}

.description {
    color: $hex-404040;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;

    &.less {
        height: 80px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

.bigImage {
    width: 310px;
    height: 300px;
    display: block;
    pointer-events: none;
    margin-top: 15px;
    border-radius: 15px;
}

.additionalImage {
    width: 600px;
    height: 400px;
    display: block;
    pointer-events: none;
    margin-top: 15px;
    border-radius: 15px;
}

.stock {
    // color: $hex-ff5050;
    color: red;
    font-size: 14px;
    font-weight: 600;
    text-align: justify;
    margin: 10px 0px 0px;
    align-items: center;
}

.bigImage2 {
    width: 400px;
    height: 400px;
    display: block;
    pointer-events: none;
    margin-top: 15px;
    border-radius: 15px;
}

.name {
    color: $hex-404040;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    @include text-truncate();
}

.cartOfferPrice {
    overflow: hidden;
    font-size: 20px;
    color: #FF215F;
    font-weight: 800;
}

.cartPrice {
    overflow: hidden;
    font-size: 20px;
    color: $hex-404040;
    font-weight: 600;
}

.cartButton {
    background: $hex-2ac8fe;
    color: $hex-ffffff;
    text-transform: capitalize;
    width: 100%;
}

.container {
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
    justify-content: center;
    padding: 15px;
    width: auto;
    border-radius: 15px;
}

.discount {

    overflow: hidden;
    font-size: 16px;
    color: #FF215F;
    font-weight: 600;
    text-transform: uppercase;
    @include text-truncate();
}