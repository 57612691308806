.buttonLink {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    border-radius: 12px;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    outline: none;
}