@import '../module-variables';

.container {
    height: calc(100vh - 71px);
    overflow-x: hidden;
    overflow-y: auto;
}

.mainContent {
    width: calc(100% - 250px);
    overflow-x: hidden;
    overflow-y: auto;

    @include media-breakpoint-down(sm) {
        width: calc(100vh - 119px);
    }
}