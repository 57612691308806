@import '../module-variables';

.container {
    width: 100px;
    height: 100px;
    border-radius: $border-radius;
    background: $hex-ffffff;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    border: 1px solid $hex-dadada;
    transition: all 0.1s linear;

    &.error {
        border: 1px solid $hex-ff5050;
        transition: all 0.1s linear;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $hex-404040;
        height: 100%;
    }
}