@import '../module-variables';

.saveButton {
    width: 100%;
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    text-transform: capitalize;
}

.sectionHeader {
    font-size: 14px;
    font-weight: 600;
    color: $hex-404040;
    margin-bottom: 2px;
}

.copyButton {
    width: 10%;
    color: $hex-404040;
    background: $hex-e0e0e0;
    text-transform: capitalize;
    margin-bottom: 25px;
    margin-right: 10px;
}

.productPreview {
    width: 20%;
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    text-transform: capitalize;
    margin-bottom: 25px;
    margin-right: 10px;
}

.planUpgradeBtn {
    width: 10%;
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    text-transform: capitalize;
    margin-right: 10px;
}