@import '../module-variables';

.title {
    font-size: 18px;
    font-weight: 800;
    color: $hex-45afe3;
    text-transform: uppercase;
    margin-bottom: 18px;
    text-align: center;
}

.description {
    font-weight: 400;
    margin: 0px;
    margin-bottom: 10px;
    text-align: justify;

}

.content {
    font-weight: 400;
    margin: 0px;
    margin-bottom: 5px;
    text-align: justify;

}

.blogTitle {
    font-size: 14px;
    font-weight: 800;
    color: $hex-45afe3;
    margin-bottom: 10px;
}

.blogDescription {
    font-weight: 600;
    margin: 0px;
    text-align: justify;
    line-height: 1.5;
    margin-bottom: 10px;
}

.blogAuthor {
    font-size: small;
    font-weight: 600;
    margin: 0px;
    text-align: justify;
    line-height: 1.5;
    color: rgb(0, 179, 255);
    text-transform: capitalize;
    margin-bottom: 10px;
}

.blogDate {
    font-size: small;
    font-weight: 600;
    margin: 0px;
    text-align: justify;
    line-height: 1.5;
    color: gray;
}

.blogButton {
    width: 100px;
}

.blogImage {
    width: 100%;
    height: 300px;
    margin-bottom: 10px;
    border-radius: $border-radius;
    overflow: hidden;
}

.image {
    width: 60%;
    height: 180px;
    margin-bottom: 35px;
    border-radius: $border-radius;
    overflow: hidden;
}

.imagediv {
    width: 484px;
    height: 215px;
}

.wholediv{
    width: 484px;
    height: 215px;
}
.blogImageSuper {
    width: 340px;
    height: 280px;
    margin-bottom: 10px;
    border-radius: $border-radius;
    overflow: hidden;
}