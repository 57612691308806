@import '../module-variables';

.container {
    width: 100%;
    max-width: 450px;
    border-radius: 24px;
    background: $hex-ffffff;
    padding: 20px;

    .logoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
        height: 200px;
    }

    .label {
        font-weight: 700;
        font-size: 25px;
        color: $hex-404040;
        text-align: center;
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        color: $hex-404040;
        text-align: center;
    }

    .text{
        padding-top: 20px;
        font-weight: 600;
        font-size: 20px;
        color: $hex-5bbc6a;
        text-align: center;
    }
    .reader {
        border: 1px solid $hex-dadada;
        width: 300px;
        height: 220px;
    }
    
    .buttonContainer {
        text-align: center;
        padding: 30px 0px 15px;

        .button {
            border: none;
            outline: none;
            width: 200px;
            background: $hex-ffffff;
            height: 50px;
            border-radius: $border-radius-pill;
            border: 2px solid $hex-5bbc6a;
            color: $hex-404040;
            font-size: 15px;
            font-weight: 600;
            align-content: center;
        }
    }
}

.eventAgreeContainer{
    width: 100%;
    max-width: 700px;
    border-radius: 24px;
    background: $hex-ffffff;
    padding: 20px;

    .comments{
        font-weight: 500;
        font-size: 15px;
        color: $hex-404040;
    }

}