@import '../module-variables';

.container {
    width: 100%;
    max-width: 278px;
    padding: 6px;
    border-radius: 16px;
    box-shadow: 0px 3px 24px $hex-4040400f;

    .image {
        height: 198px;
        border-radius: 16px;
        background: $hex-868e96;
    }
}