@import '../module-variables';


.timeContainer{
    position: relative;
    display: flex;
    flex-direction: column;

    .label{
        font-size: 14px;
        font-weight: 600;
        color: $hex-404040;
        margin-bottom: 3px;
        display: inline-block;
        max-width: 100%;
        @include text-truncate();
    }

    .timeCard{
        position: relative;
    
        .timeInput{
            border: 1px solid #dadada;
            width: 100%;
            border-radius: 12px;
            padding: 0px 16px;
            height: 48px;
            border-radius: 16px;
            border: 1px solid $hex-dadada;
    
            .periods{
                cursor: pointer;
            }
            .clock{
                cursor: pointer;
            }
        }
    
        .timeItems{
            display: none;
            position: absolute;
            width: 100%;
            background-color: #f9f9f9;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            margin-top: 2px;
            border: 1px solid #dadada;
            padding: 10px 20px;
            max-height: 200px;
            overflow-y: scroll;
            border-radius:0px 0px 20px 20px;
            z-index: 1;
    
            .timeItem{
                cursor: pointer;
                border-bottom: 1px solid #dadada;
    
                &:hover{
                    background-color: #f1f1f1
                }
            }
    
            &.active{
                display: block;
            }
        }
    }
    
}