@import '../module-variables';

.label {
    font-weight: 600;
}

.requiredLabel {
    font-size: 14px;
    color: red;
}

.weAccept {
    color: $hex-929292;
    font-size: 14px;
    font-weight: 600;
    margin-left: 24px;
    padding-left: 16px;
}

.button {
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    width: 100%;

    &:hover {
        color: $hex-ffffff;
    }
}

.paytrailMobile {
    width: 50%;
    margin-bottom: 4px;
    border-radius: 15px;
}

.paytrailFoodWeb {
    width: 100%;
    margin-bottom: 4px;
    border-radius: 15px;
}

.paytrailEventWeb {
    width: 50%;
    margin-bottom: 4px;
    border-radius: 15px;
}

.previewContainer {
    margin-top: 1rem;

    h6 {
        margin-bottom: 0.5rem; // Adjust spacing between title and image
        text-align: left; // Left-align the title
    }

    .imagePreview {
        display: block; // Ensure it takes only its own width
        max-width: 100%; // Prevent overflow
        height: auto; // Maintain aspect ratio
        border: 1px solid #ccc;
        border-radius: 8px;

        // Align image to the left
        margin: 0; // Remove default margin
    }

    // Responsive adjustments if needed
    @media (min-width: 576px) {
        .imagePreview {
            max-width: 80%; // Scale for tablets
        }
    }

    @media (min-width: 768px) {
        .imagePreview {
            max-width: 60%; // Scale for larger screens
        }
    }

    @media (min-width: 992px) {
        .imagePreview {
            max-width: 40%; // Scale for desktops
        }
    }
}