@import '../module-variables';

.modeContainer {
    padding: 0px;
    width: 48px;
    height: 48px;
    overflow: hidden;
    background: $hex-ec3b50;
    cursor: pointer;

    select {
        width: 100%;
        height: 100%;
        text-align: center;
        background: $hex-ec3b50;
        color: $hex-ffffff;
        border: none;
        cursor: pointer;
    }
}

.maxCountContainer {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.generate {
    background: $hex-ec3b50;
    color: $hex-ffffff;
}