@import '../module-variables';

.imageWrapper {
    // 'max-w-280px h-210px d-flex align-items-center justify-content-center br-16px shadow-sm overflow-hidden'
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        object-position: center;
    }

    @include media-breakpoint-up(md) {
        & {
            max-width: 278px;
            width: 100%;
            height: 206px;
        }
    }
}

.shopName {
    font-size: 18px;
    color: $hex-404040;
    font-weight: 800;
    text-transform: uppercase;
    @include text-truncate();
}

.infoIcon {
    cursor: pointer;
    margin-top: -5px;
}

.description {
    font-size: 14px;
    color: $hex-404040;
    font-weight: 400;
    @include text-truncate();
}

.city {
    font-size: 14px;
    color: $hex-404040;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid skyblue;
}

.landmark {
    font-size: 14px;
    color: $hex-929292;
    font-weight: 400;
    @include text-truncate();
}

.phoneNumber {
    @extend .city;
}

.star {
    margin-top: -5px
}

.rating {
    font-size: 14px;
    color: $hex-404040;
    font-weight: 600;
}

.readReviews {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: $hex-404040;

    &:hover {
        color: $hex-404040;
    }
}

.barrier {
    // 'h-20px me-4 w-1px bg-929292 d-none d-lg-block'
    width: 1px;
    height: 20px;
    background: $hex-929292;
    display: none;
    margin-right: 24px;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.addToFavourites {
    color: $hex-404040;

    .label {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
    }

    &:hover {
        color: $hex-404040;
    }
}

.offers {
    font-size: 14px;
    color: $hex-404040;
    font-weight: 600;
    // margin-top: 16px;
}

.shopInformation {
    .subHeader {
        color: $hex-404040;
        margin-bottom: 3px;
        font-weight: 600;
        font-size: 14px;
    }

    .description {
        color: $hex-404040;
        font-size: 14px;
    }

    td,
    th {
        font-size: 14px;
        padding: 5px;
    }

    .error {
        color: $hex-ec3b50;
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0px;
    }
}