@import 'module-variables';

.swiper-pagination {
    bottom: 0px !important;

    &-bullet {
        --size: 12px;
        width: var(--size);
        height: var(--size);
        background: $hex-dadada;
        margin: 0px 10px;
        opacity: 1;

        &#{&}-active {
            background: $hex-404040;
        }
    }

}

.swiper-button {

    &-next,
    &-prev {
        --size: 35px;
        width: var(--size);
        height: var(--size);
        color: $hex-404040;
        background: $hex-dadada;
        border-radius: 50%;
        display: none;

        &::after {
            font-size: 15px;
            font-weight: 900;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    &-next {
        padding-left: 3px;
        right: 5px;
    }

    &-prev {
        padding-right: 3px;
        left: 5px;
    }
}