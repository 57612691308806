@import '../module-variables';

.container {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .content {
        width: 16px;
        border: 1px solid $hex-2ac8fe;
        background: $hex-2ac8fe;
    }
}