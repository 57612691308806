@import '../module-variables';

.customCarousel {
    padding-bottom: 20px;
}

.customCarouselImg {
    height: 50vh;
    object-fit: cover;
    border-radius: 15px;
}

.caption {
    font-size: 50px;
    font-weight: 800;
    color: $hex-e0e0e0;
    text-transform: uppercase;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
}

.imageWrapper {
    // 'max-w-280px h-210px d-flex align-items-center justify-content-center br-16px shadow-sm overflow-hidden'
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;

    img {
        width: 100%;
        height: 50vh;
        object-fit: fill;
        object-position: center;
    }

    @include media-breakpoint-up(md) {
        & {
            max-width: 100%;
            width: 100%;
            height: 50vh;
        }
    }
}