@import '../module-variables';

.mainContent {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    .input {
        width: 100%;
        height: 48px;
        padding: 0px 16px;
        color: $hex-404040;
        border-radius: 16px 0px 0px 16px;
        border-right: none;
        border-top: 1px solid $hex-dadada;
        border-left: 1px solid $hex-dadada;
        border-bottom: 1px solid $hex-dadada;

        &.error {
            border-top: 1px solid $hex-ff5050;
            border-left: 1px solid $hex-ff5050;
            border-bottom: 1px solid $hex-ff5050;
        }
    }

    .picker {
        width: 48px;
        height: 48px;
        border-radius: 0px 16px 16px 0px;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid $hex-dadada;
        display: flex;
        align-items: center;
        justify-content: center;

        &.error {
            border: 1px solid $hex-ff5050;
        }
    }

    .colorInput {
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
}