@import '../module-variables';

.container {
    height: 71px;
    background: $hex-ffffff;

    .menuButton {
        cursor: pointer;
    }

    .logo {
        width: 141px;
        height: 55px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .logoCaption {
        font-size: 12px;
        color: $hex-929292;
        font-weight: 600;
    }
}

.menuIcon {
    cursor: pointer;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.logoCaption {
    display: none;
    font-size: 12px;
    color: $hex-929292;
    font-weight: 600;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}