@import '../module-variables';


.container {
    width: 100%;
    max-width: 1000px;
    border-radius: 24px;
    background-image: linear-gradient($hex-d1c7c5, #FFF 60%);
    padding: 5px;

    .cardContainer {
        position: relative;

        .closeButton {
            position: absolute;
            top: 30px;
            display: none;
            left: 950px;

            @include media-breakpoint-up(lg) {
                display: block;
            }

            &.leftView {
                display: block;
            }
        }

        .logoContainer {
            text-align: center;

            .image {
                width: 208px;
                height: 88px;
            }
        }

        .leftSection {
            padding: 10px;
            display: none;

            .topContainer {
                bottom: 200px;

                .topSection {
                    display: none;
                    width: 100%;

                    .imageSection {
                        text-align: center;
                        margin-top: 40px;
                        margin-bottom: 35px;

                        .image1Container {
                            margin-bottom: 150px;

                            .image1 {
                                width: 190px;
                            }

                            .ellipse1 {
                                margin: 0px 30px;
                                width: 250px;
                                flex-shrink: 0;
                                height: 26px;
                                border-radius: 250px;
                                background: rgba(0, 0, 0, 0.10);
                                filter: blur(10px);

                                @include media-breakpoint-up(md) {
                                    margin: 0px 240px;
                                }

                                @include media-breakpoint-up(lg) {
                                    margin: 0px 150px;
                                }
                            }

                            .ellipse2 {
                                margin: 0px 90px;
                                width: 124px;
                                height: 18px;
                                flex-shrink: 0;
                                background: rgba(0, 0, 0, 0.10);
                                filter: blur(10px);

                                @include media-breakpoint-up(md) {
                                    margin: 0px 320px;
                                }

                                @include media-breakpoint-up(lg) {
                                    margin: 0px 200px;
                                }
                            }
                        }

                        .image1Container1 {
                            margin: 30px 0px 0px -120px;

                            .image1 {
                                width: 190px;
                            }

                            .ellipse {
                                margin: 10px 120px;
                                width: 132px;
                                height: 15px;
                                flex-shrink: 0;
                                background: var(--Netral-Black, #000);
                                opacity: 0.25;
                                filter: blur(10px);

                                @include media-breakpoint-up(md) {
                                    margin: 10px 340px;
                                }

                                @include media-breakpoint-up(lg) {
                                    margin: 10px 240px;
                                }
                            }
                        }

                        .image1Container2 {
                            margin: -80px 0px 20px 135px;

                            .image2 {
                                width: 160px;
                            }
                        }

                        &.animation {
                            animation: slideInRight 1s, pop 0.5s, slideOutLeft 1s;
                            animation-delay: 0s, 2s, 4.1s;
                        }
                    }

                    .contentSection {
                        text-align: justify;
                        padding: 0px 20px;

                        .headerText {
                            color: $hex-404040;
                            font-size: 22px;
                            font-weight: 600;
                            font-style: normal;
                            line-height: 120%;
                        }

                        .text {
                            color: $hex-404040;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                        }

                        @include media-breakpoint-up(md) {
                            padding: 0px 140px;
                        }

                        @include media-breakpoint-up(lg) {
                            padding: 0px 120px;
                        }


                        &.animation {
                            animation: slideInRight 1s, pop 0.5s, slideOutLeft 1s;
                            animation-delay: 0s, 2s, 4.1s;
                        }
                    }

                    &.active {
                        display: inline-block;
                    }
                }
            }

            .bottomContainer {
                margin-top: 25px;

                .skipButton {
                    margin-right: 50px;
                    color: #989CA2;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%;

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }

                .pointerContainer {

                    .pointer {
                        width: 58px;
                        height: 4px;
                        border-radius: 100px;
                        background: var(--Dark-Black-200, #E8EAED);

                        &.active {
                            background: $hex-404040;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        margin-left: 180px;
                    }

                    @include media-breakpoint-up(lg) {
                        margin-left: 180px;
                    }

                }


            }

            &.active {
                display: block;
            }
        }

        .rightSection {
            padding: 20px;
            display: none;

            .topContainerRight {
                position: relative;
                // margin-top: 100px;
                // height: 320px;

                .errorMessage {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    position: absolute;
                    color: red
                }

                .formContainer {
                    position: relative;

                    .alertIcon {
                        display: none;
                        position: absolute;
                        right: 20px;
                        top: 10px;

                        &.error {
                            display: block;
                        }
                    }

                    .input {
                        width: 100%;
                        height: 48px;
                        padding: 0px 14px;
                        color: $hex-404040;
                        border-radius: 4px;
                        border: 1px solid $hex-8f8f91;

                        &.withRightRenderer {
                            border-radius: 4px 0px 0px 4px;
                        }

                        &.withLeftRenderer {
                            border-radius: 0px 4px 4px 0px;
                            padding: 0px 10px;
                        }

                        &.error {
                            border: 1px solid $hex-ff5050;
                        }
                    }

                    .codeContainer {
                        position: absolute;
                        width: 100%;
                        margin-top: 2px;
                        border: 1px solid $hex-dadada;
                        padding: 15px;
                        box-shadow: 2px 2px 10px $hex-dadada;
                        border-radius: 10px;
                        max-height: 150px;
                        overflow-y: auto;
                        display: none;
                        background: #fff;
                        z-index: 999;
                        top: 50px;

                        .code {
                            border-bottom: 1px solid $hex-dadada;
                            padding: 2px;

                            &:hover {
                                background: $hex-dadada;
                                border-radius: 2px;
                                cursor: pointer;
                            }
                        }

                        &.active {
                            display: block;
                        }
                    }

                    .leftRenderer {
                        transition: border-color 0.2s linear;
                        user-select: none;
                        height: 48px;
                        border-radius: 4px 0px 0px 4px;
                        padding: 0px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border-left: none;
                        border-top: 1px solid $hex-8f8f91;
                        border-bottom: 1px solid $hex-8f8f91;
                        border-left: 1px solid $hex-8f8f91;

                        &.error {
                            border-top: 1px solid $hex-ff5050;
                            border-bottom: 1px solid $hex-ff5050;
                            border-left: 1px solid $hex-ff5050;
                        }
                    }

                    .rightRenderer {
                        transition: border-color 0.2s linear;
                        user-select: none;
                        height: 48px;
                        border-radius: 0px 4px 4px 0px;
                        padding: 0px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border-left: none;
                        border-top: 1px solid $hex-8f8f91;
                        border-bottom: 1px solid $hex-8f8f91;
                        border-right: 1px solid $hex-8f8f91;

                        &.error {
                            border-top: 1px solid $hex-ff5050;
                            border-bottom: 1px solid $hex-ff5050;
                            border-right: 1px solid $hex-ff5050;
                        }
                    }
                }

            }

            .topContainerRight2 {
                position: relative;
                // margin-top: 100px;
                // height: 300px;

                .errorMessage {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    position: absolute;
                    color: red
                }

                .formContainer {
                    position: relative;

                    .alertIcon {
                        display: none;
                        position: absolute;
                        right: 20px;
                        top: 10px;

                        &.error {
                            display: block;
                        }
                    }

                    .input {
                        width: 100%;
                        height: 48px;
                        padding: 0px 14px;
                        color: $hex-404040;
                        border-radius: 4px;
                        border: 1px solid $hex-8f8f91;

                        &.withRightRenderer {
                            border-radius: 4px 0px 0px 4px;
                        }

                        &.withLeftRenderer {
                            border-radius: 0px 5px 5px 0px;
                            padding: 0px 10px;
                        }

                        &.error {
                            border: 1px solid $hex-ff5050;
                        }
                    }

                    .leftRenderer {
                        transition: border-color 0.2s linear;
                        user-select: none;
                        height: 48px;
                        border-radius: 16px 0px 0px 16px;
                        padding: 0px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border-left: none;
                        border-top: 1px solid $hex-dadada;
                        border-bottom: 1px solid $hex-dadada;
                        border-left: 1px solid $hex-dadada;

                        &.error {
                            border-top: 1px solid $hex-ff5050;
                            border-bottom: 1px solid $hex-ff5050;
                            border-left: 1px solid $hex-ff5050;
                        }
                    }

                    .rightRenderer {
                        transition: border-color 0.2s linear;
                        user-select: none;
                        height: 48px;
                        border-radius: 0px 16px 16px 0px;
                        padding: 0px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border-left: none;
                        border-top: 1px solid $hex-dadada;
                        border-bottom: 1px solid $hex-dadada;
                        border-right: 1px solid $hex-dadada;

                        &.error {
                            border-top: 1px solid $hex-ff5050;
                            border-bottom: 1px solid $hex-ff5050;
                            border-right: 1px solid $hex-ff5050;
                        }
                    }
                }
            }

            .buttonContainerRight {
                text-align: center;
                margin-top: 30px;

                .prevbutton {
                    border: none;
                    outline: none;
                    width: 100%;
                    background: $hex-dadada;
                    height: 40px;
                    border-radius: 4px;
                    margin-bottom: 8px;
                    border: 2px solid $hex-dadada;
                    color: $hex-404040;
                    font-size: 15px;
                    font-weight: 600;
                    align-content: center;
                }

                .button {
                    border: none;
                    outline: none;
                    width: 100%;
                    background: $hex-2432da;
                    height: 40px;
                    border-radius: 4px;
                    border: 2px solid $hex-2432da;
                    color: $hex-ffffff;
                    font-size: 15px;
                    font-weight: 600;
                    align-content: center;
                }
            }

            @include media-breakpoint-up(lg) {
                display: block;
            }

            &.rightSectionActive {
                display: block;
            }

        }
    }


}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(20%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes pop {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.06);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slideOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-20%);
    }
}

.required {
    color: red;
}

.logoCaption {
    padding-top: 5px;
    display: none;
    font-size: 12px;
    color: $hex-929292;
    font-weight: 600;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}

.logoWrapper {
    padding-top: 5px;
    width: 190px;
    // height: 55px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}