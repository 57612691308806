@import '../module-variables';

.newLanguageInput {
    height: 34px;
    color: $hex-404040;
    border: 1px solid $hex-dadada;
    border-radius: $border-radius;
    box-shadow: $box-shadow-sm;
}

.changeFieldButton {
    cursor: pointer;
    display: inline-block;
    border: 1px solid $hex-dadada;
    border-radius: $border-radius;
}
.addNewLanguageButton {
    user-select: none;
    cursor: pointer;
    display: inline-block;
    background: $hex-2ac8fe;
    color: $hex-ffffff;
    text-transform: capitalize;
    border: 1px solid $hex-dadada;
    border-radius: $border-radius;
    box-shadow: $box-shadow-sm;
}

.cancelLanguageButton {
    @extend .addNewLanguageButton;
}