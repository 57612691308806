@import '../module-variables';

.title {
    font-size: 18px;
    font-weight: 800;
    color:$hex-45afe3;
    text-transform: uppercase;
    margin-bottom: 18px;
}
.description {
    font-weight: 400;
    margin: 0px;
}

