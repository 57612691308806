@import '../module-variables';

.container {
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin: 10px 0px 30px;
    overflow: hidden;
    border-radius: 18px;
    max-width: 280px;
    transition: all 0.1s linear;

    &:hover {
        transform: scale(1.05);
        box-shadow: $box-shadow;
        transition: all 0.2s linear;
    }

    .content {
        display: flex;
        height: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        border: 1px solid $hex-dadada;
        border-radius: 18px;
        overflow: hidden;
        max-width: 250px;

        @include media-breakpoint-up(sm) {
            max-width: 400px;
        }

        .leftSection {
            width: 100%;
            padding: 12px;
            order: 2;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-direction: column;

            .infoSection {
                .shopName {
                    // color: $hex-404040;
                    // font-size: 14px;
                    font-weight: 800;
                    margin-bottom: 2px;
                    color: #ff5656;
                    font-size: 15px;
                    @include text-truncate();
                }

                .offerText {
                    @extend .shopName;
                    margin-bottom: 4px;
                }

                .description {
                    font-size: 14px;
                    font-weight: 400;
                    margin: 0px;
                    height: 40px;
                    color: $hex-404040;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .rightSection {
            width: 100%;
            order: 1;
            height: 198px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}