@import '../module-variables';

.container {
    border-radius: 18px;
    border: 1px solid $hex-dadada;
    padding: 24px;
    margin-bottom: 24px;

    .title {
        color: $hex-404040;
        font-size: 18px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    .applyVoucher {
        margin-bottom: 16px;

        .applyVoucherButton {
            width: 100%;
            background: $hex-ffffff;
            border: 1px solid $hex-dadada;
            display: flex;
            justify-content: center;

            .label {
                color: $hex-404040;
                font-weight: 600;
                font-size: 14px;
            }
        }

        .applyButton,
        .cancelButton {
            width: 100%;
            margin-top: 8px;
        }

        .applyButton {
            color: $hex-ffffff;
            background: $hex-2ac8fe;

            &:hover {
                color: $hex-ffffff;
                background: $hex-2ac8fe;
            }
        }

        .cancelButton {
            background: $hex-ffffff;
            border: 1px solid $hex-ec3b50;
            color: $hex-ec3b50;

            &:hover {
                background: $hex-ec3b50;
                border: 1px solid $hex-ec3b50;
                color: $hex-ffffff;
            }
        }

        .codeApplied {
            width: 100%;
            background: $hex-ffffff;
            border: 1px dashed $hex-dadada;
            border-radius: 16px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                color: $hex-404040;
                font-weight: 500;
                font-size: 14px;
                padding: 10px 15px;
                @include text-truncate();
            }

            .right {
                color: $hex-404040;
                font-weight: 600;
                font-size: 14px;
                text-decoration: underline;
                padding: 10px 15px;
                cursor: pointer;
            }
        }
    }

    .priceSection {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .strongText {
            font-size: 14px;
            font-weight: 600;
            color: $hex-404040;
        }

        .lightText {
            @extend .strongText;
            color: $hex-929292;
        }
    }

    .horizontalLine {
        width: 100%;
        border-bottom: 1px solid $hex-dadada;
        margin: 16px 0px;
    }

    .inTotal {
        font-size: 18px;
        font-weight: 600;
        color: $hex-404040;
        text-transform: uppercase;
    }
}