@import '../module-variables';

.orderMenuLink {
    // {`user-select-none text-uppercase fs-16px fw-600 c-pointer py-2 px-3 rounded bg-${props.active ? 'ec3b50 text-white' : 'ffffff text-404040'} border shadow-sm`}
    user-select: none;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: $border-radius;
    background: $hex-ffffff;
    color: $hex-404040;
    border: 1px solid $hex-dadada;
    box-shadow: $box-shadow-sm;

    &.active {
        background: $hex-ec3b50;
        color: $hex-ffffff;
    }
}

.csvDownloadBtn {
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    text-transform: capitalize;
}

.infiniteScrollText {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: $hex-404040;
}

.firstOption::after {
    content: '📌';
    /* You can use a pin emoji or customize with an icon font */
    position: absolute;
    right: 8px;
    color: #404040;
    font-size: 14px;
}