@import '../module-variables';

.container {
    position: relative;
    display: flex;
    flex-direction: column;

    .labelContanier {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
            font-size: 14px;
            font-weight: 600;
            color: $hex-404040;
            margin-bottom: 3px;
            display: inline-block;
            max-width: 100%;
            @include text-truncate();
        }
    }

    & input,
    & select {
        transition: border-color 0.2s linear;
    }
}

.required {
    color: red;
}

.input {
    width: 100%;
    height: 48px;
    padding: 0px 16px;
    color: $hex-404040;
    border-radius: 16px;
    border: 1px solid $hex-dadada;

    &.withRightRenderer {
        border-radius: 16px 0px 0px 16px;
    }

    &.withLeftRenderer {
        border-radius: 0px 16px 16px 0px;
        padding: 0px 10px;
    }

    &.error {
        border: 1px solid $hex-ff5050;
    }
}

.leftRenderer {
    transition: border-color 0.2s linear;
    user-select: none;
    height: 48px;
    border-radius: 16px 0px 0px 16px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-left: none;
    border-top: 1px solid $hex-dadada;
    border-bottom: 1px solid $hex-dadada;
    border-left: 1px solid $hex-dadada;

    &.error {
        border-top: 1px solid $hex-ff5050;
        border-bottom: 1px solid $hex-ff5050;
        border-left: 1px solid $hex-ff5050;
    }
}

.rightRenderer {
    transition: border-color 0.2s linear;
    user-select: none;
    height: 48px;
    border-radius: 0px 16px 16px 0px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-left: none;
    border-top: 1px solid $hex-dadada;
    border-bottom: 1px solid $hex-dadada;
    border-right: 1px solid $hex-dadada;

    &.error {
        border-top: 1px solid $hex-ff5050;
        border-bottom: 1px solid $hex-ff5050;
        border-right: 1px solid $hex-ff5050;
    }
}