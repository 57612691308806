@import '../module-variables';

.container {
    width: 100%;
    max-width: 880px;
    border-radius: 24px;
    background: $hex-ffffff;

    &.small {
        max-width: 550px;
    }
    &.large {
        max-width: 1350px;
    }
    .headerText {
        color: $hex-404040;
        font-size: 24px;
        font-weight: 600;
    }

    .mainContent {
        overflow: auto;
        max-height: calc(100vh - 250px);
        padding-bottom: 10px;
        &.large {
            max-height: calc(100vh - 200px);
        }
    }
}

.saveButton {
    width: 100%;
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    text-transform: capitalize;
}