@import '../module-variables';

.title {
    font-size: 18px;
    font-weight: 800;
    color: $hex-404040;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.radioButtonLabel {
    font-weight: 600;
}

.notAvailable {
    color: $hex-fe7a5b;
}

.topAlertBanner {
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    z-index: 200;

    .alert {
        background: $hex-ec3b50;
        padding: 10px;
        border-radius: $border-radius;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .alertText {
            color: $hex-ffffff;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.volunteerLabel {
    font-size: 16px;
    font-weight: 600;
    color: $hex-404040;
    margin-bottom: 2px;
}

.requiredLabel {
    font-size: 14px;
    color: red;
}

.link {
    color: $hex-404040;
    font-size: 14px;
    font-weight: 600;

    &:hover {
        color: $hex-404040;
    }
}

.capacityContainer {
    width: 100%;
    height: 100%;
    border: 1px solid $hex-dadada;
    border-radius: 16px;

    .capacityList {
        padding: 10px 30px 10px 30px;
        // width: 300px;

        .capacitySelectedBox {
            width: 60px;
            height: 30px;
            border-radius: 10px;
            background-color: $hex-4ab96a;
            text-align: center;
            padding: 5px;
            color: $hex-ffffff;
            border: 1px solid $hex-dadada;
        }

        .previewContainer {
            margin-top: 1rem;
            text-align: center;

            .imagePreview {
                width: 100%; // Default to full width
                max-width: 100%;
                height: auto; // Maintain aspect ratio
                border: 1px solid #ccc;
                border-radius: 8px;

                // Responsive styles
                @media (min-width: 576px) {
                    // Small devices like tablets
                    max-width: 80%; // Adjust to 80% width
                }

                @media (min-width: 768px) {
                    // Medium devices like larger tablets
                    max-width: 60%;
                }

                @media (min-width: 992px) {
                    // Large devices like desktops
                    max-width: 40%;
                }
            }
        }

        .capacitySelectBox {
            width: 60px;
            height: 30px;
            border-radius: 10px;
            background-color: $hex-3ec6f2;
            text-align: center;
            padding: 5px;
            color: $hex-ffffff;
            cursor: pointer;
            border: 1px solid $hex-dadada;
        }

        .capacityBox {
            width: 60px;
            height: 30px;
            border-radius: 10px;
            background-color: $hex-ec3b50;
            text-align: center;
            padding: 5px;
            color: $hex-ffffff;
            border: 1px solid $hex-dadada;
        }
    }
}