@import '../module-variables';

.headerText {
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    color: $hex-404040;
    text-align: center;

    &.start {
        text-align: left;
    }
}

.viewAll {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    .label {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: $hex-383838;
        height: 12px;
    }
}

.sectionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}