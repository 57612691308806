@import '../module-variables';

.container {
    border: 1px solid $hex-dadada;
    border-radius: $border-radius-pill;
    display: inline-block;

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 5px;
        height: 70%;

        .label {
            color: $hex-404040;
            font-size: 10px;
            font-weight: 600;
        }
    }
}