@import '../module-variables';

.description {
    color: $hex-404040;
    font-size: 14px;
    font-weight: 400;
    text-align: start;
}

.link {
    color: $hex-404040;
    font-size: 14px;
    font-weight: 600;

    &:hover {
        color: $hex-2432da;
    }
}