@import '../module-variables';

.title {
    font-size: 18px;
    font-weight: 800;
    color:$hex-45afe3;
    text-transform: uppercase;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
}

.phonenumber{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
}

.email{
    font-size: 18px;
    font-weight: 800;
    color:$hex-45afe3;
    text-transform: uppercase;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
}

.message{
    font-size: 18px;
    font-weight: 800;
    color:$hex-45afe3;
    text-transform: uppercase;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
}

.image{
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
        margin-bottom: 10px;
    }
}

.submit {
    width: 20%;
    color: $hex-ffffff;
    background: $hex-2ac8fe;
    text-transform: capitalize;
    float: right;
    margin-bottom: 10px;
}
 

