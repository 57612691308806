@import '../module-variables';

.iconWrapper {
    border: 1px solid $hex-dadada;
    border-right: none;
    border-radius: 16px 0px 0px 16px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
}

.input {
    width: 100%;
    border: 1px solid $hex-dadada;
    border-left: none;
    border-right: none;
    height: 48px;
    padding-right: 16px;
    color: $hex-404040;
}

.button {
    padding: 0px 24px;
    height: 48px;
    border: 1px solid $hex-dadada;
    border-radius: 0px 16px 16px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $hex-404040;
    font-weight: 600;
}