@import '../module-variables';

.textArea {
    width: 100%;
    padding: 8px 16px;
    color: $hex-404040;
    border-radius: 16px;
    border: 1px solid $hex-dadada;

    &.error {
        border: 1px solid $hex-ff5050;
    }
}