@import '../module-variables';

.container {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .line1 {
        width: 16px;
        border: 1px solid $hex-2ac8fe;
        background: $hex-2ac8fe;
        transform: rotate(90deg);
    }

    .line2 {
        width: 16px;
        border: 1px solid $hex-2ac8fe;
        background: $hex-2ac8fe;
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translateY(-50%);
    }
}