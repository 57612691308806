@import '../module-variables';

.shoppingCart {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background: $hex-00000066;
    z-index: 300;

    &.fadeIn {
        opacity: 1;
        transition: opacity 0.2s linear;
    }

    &.fadeOut {
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    .shoppingCartBody {
        width: 670px;
        height: 100%;
        background: $hex-ffffff;
        border-radius: 24px 0px 0px 24px;
        margin-right: -670px;
        transition: margin-right 0.2s linear;

        &.slideIn {
            margin-right: 0px;
            transition: margin-right 0.2s linear;
        }

        &.slideOut {
            margin-right: -670px;
            transition: margin-right 0.2s linear;
        }

        .title {
            color: $hex-404040;
            font-size: 18px;
            font-weight: 800;
            text-transform: uppercase;
        }

        .cartItemsContainer {
            overflow: auto;
            max-height: calc(100vh - 420px);
        }

        .netTotal {
            font-size: 14px;
            font-weight: 600;
            color: $hex-404040;
        }

        .tax {
            @extend .netTotal;
            color: $hex-929292;
        }

        .horizontalLine {
            width: 100%;
            border-bottom: 1px solid $hex-dadada;
            margin: 12px 0px;
        }

        .inTotal {
            font-size: 18px;
            font-weight: 600;
            color: $hex-404040;
            text-transform: uppercase;
        }

        .checkoutButton {
            background: $hex-2ac8fe;
            color: $hex-ffffff;
            width: 100%;

            &:disabled {
                background: $hex-929292;
                cursor: no-drop;
            }

            &:hover {
                color: $hex-ffffff;
            }
        }

        .cartEmpty {
            text-align: center;

            .animation {
                height: 350px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .label {
                font-size: 22px;
                font-weight: 700;
                color: $hex-404040;
                padding: 30px 0px;
            }
        }
    }
}

.sharingTitle {
    color: $hex-404040;
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 20px;
}

.sharingCheckoutButton {
    background: $hex-2ac8fe;
    color: $hex-ffffff;
    width: 100%;

    &:disabled {
        background: $hex-929292;
        cursor: no-drop;
    }

    &:hover {
        color: $hex-ffffff;
    }
}

.sharingNetTotal {
    font-size: 14px;
    font-weight: 600;
    color: $hex-404040;
}

.sharingTax {
    @extend .netTotal;
    color: $hex-929292;
}

.sharingHorizontalLine {
    width: 100%;
    border-bottom: 1px solid $hex-dadada;
    margin: 12px 0px;
}

.sharingInTotal {
    font-size: 18px;
    font-weight: 600;
    color: $hex-404040;
    text-transform: uppercase;
}