@import '../module-variables';

.radioWrapper {
    cursor: pointer;
    display: inline-block;

    .check {
        width: 24px;
        height: 24px;
        display: inline-block;
    }

    .label {
        font-size: 14px;
        color: $hex-404040;
        font-weight: 400;
        display: inline-block;
        margin-left: 16px;
    }
}