@import '../module-variables';

.container {
    width: 100%;
    max-width: 580px;
    border-radius: 24px;
    background: $hex-ffffff;
    overflow: auto;

    .headerText {
        color: $hex-404040;
        font-size: 18px;
        font-weight: 800;
        text-transform: uppercase;
    }

    .imageContainer {
        width: 100%;
        max-width: 248px;
        height: 187px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 16px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .productName {
        font-size: 16px;
        font-weight: 600;
        color: $hex-404040;
        @include text-truncate();
    }

    .location {
        font-size: 12px;
        font-weight: 600;
        color: $hex-404040;
        cursor: pointer;
        border-bottom: 1px solid skyblue;
        @include text-truncate();
    }


    .description {
        color: $hex-404040;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        margin: 10px 0px 0px;

        &.less {
            height: 80px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }

    .stock {
        color: red;
        font-size: 14px;
        font-weight: 600;
        text-align: justify;
        margin: 10px 0px 0px;
    }

    .price {
        @extend .productName;
        font-size: 18px;
    }

    .editOrderHeaderText {
        font-size: 16px;
        color: $hex-404040;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    .editOrderContent {
        @include media-breakpoint-up(md) {
            & {
                // overflow: auto;
                max-height: calc(100vh - 500px);
            }
        }
    }

    .offerPrice {
        text-decoration: line-through;
        color: $hex-ec3b50;
    }
}

.bigImage {
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
    border-radius: 5px;
}

.editBigImage {
    width: 200px;
    height: 200px;
    display: block;
    pointer-events: none;
    border-radius: 5px;
}

.editDescription {
    color: $hex-404040;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    // margin: 10px 0px 0px;

}