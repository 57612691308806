@import '../module-variables';

.container {
    width: 100px;
    height: 100px;
    border-radius: $border-radius;
    border: 1px solid $hex-dadada;
    box-shadow: $box-shadow-sm;
    background: $hex-ffffff;
    overflow: hidden;
    position: relative;

    .close {
        display: none;
        position: absolute;
        top: 0%;
        right: 0%;
        background: $hex-ffffff;
        border-radius: 50%;
        padding: 1px;
    }

    &:hover .close {
        display: block;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.containerOtherFood {
    overflow: hidden;
    position: relative;

    .closeOtherFood {
        display: none;
        position: absolute;
        top: 10px;
        right: 0%;
        z-index: 999;
        background: $hex-ffffff;
        border-radius: 50%;
        padding: 1px;
    }

    &:hover .closeOtherFood {
        display: block;
    }

    
}