// Color

$hex-ffffff: #ffffff;
$hex-dadada: #dadada;
$hex-404040: #404040;
$hex-00000029: #00000029;
$hex-00000066: #00000066;
$hex-4040400f: #4040400f;
$hex-929292: #929292;
$hex-707070: #707070;
$hex-2ac8fe: #2ac8fe;
$hex-383838: #383838;
$hex-e0e0e0: #e0e0e0;
$hex-00000014: #00000014;
$hex-63be6b: #63be6b;
$hex-5bbc6a: #5bbc6a;
$hex-4ab96a: #4ab96a;
$hex-3ec6f2: #3ec6f2;
$hex-ec3b50: #ec3b50;
$hex-fe7a5b: #fe7a5b;
$hex-ff5050: #ff5050;
$hex-868e96: #868e96;
$hex-111827: #111827;
$hex-29303d: #29303d;
$hex-45afe3:#45afe3;
$hex-d1c7c5:#d1c7c5;
$hex-2432da:#2432da;
$hex-8f8f91: #8f8f91;
// Font

$font-family: GothicA1;