@import '../module-variables';

.container{
    position: absolute;
    width: 100%;
    margin-top: 2px;
    border: 1px solid $hex-dadada;
    padding: 15px;
    box-shadow: 2px 2px 10px $hex-dadada;
    border-radius: 10px;
    max-height: 150px;
    overflow-y: auto;
    display: none;
    background: #fff;
    z-index: 999;
    top: 75px;
    .code{
        border-bottom: 1px solid $hex-dadada;
        padding: 2px;

        &:hover{
            background: $hex-dadada;
            border-radius: 2px;
            cursor: pointer;
        }
    }
    
    &.active{
        display: block;
    }

}