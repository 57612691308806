@import '../module-variables';

.parent {
    position: relative;

    .tooltip {
        display: none;
        position: absolute;
        left: 50%;
        bottom: 100%;
        margin-bottom: 8px;
        transform: translateX(-50%);
        padding: 12px 24px;
        border-radius: $border-radius;
        background: $hex-404040;
        color: $hex-ffffff;
        font-size: 14px;

        .arrow {
            width: 10px;
            height: 10px;
            position: absolute;
            top: 100%;
            left: 50%;
            background: $hex-404040;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }

    &:hover .tooltip {
        display: block;
    }
}