@import '../module-variables';

.orderNumber {
    font-weight: 600;
    color: $hex-404040;
    font-size: 14px;
}

.orderStatus {
    font-size: 12px;
    text-transform: uppercase;
    background: $success;
    display: inline-block;
    padding: 4px 8px;
    color: $hex-ffffff;
    border-radius: $border-radius;
}

.orderDate {
    @extend .orderNumber;
}

.bookingTitle {
    color: $hex-404040;
    font-weight: 700;
    font-size: 16px;
    margin-top: 5px;
    border-bottom: 1px dotted rgb(203, 200, 200);
    text-transform: uppercase;
}

.sectionHeader {
    color: $hex-404040;
    font-weight: 700;
    font-size: 16px;
    margin-top: 16px;
    text-transform: uppercase;
}

.itemTitle {
    @extend .orderNumber;
}

.price {
    @extend .itemTitle;
    margin-bottom: 16px;
}

.itemExtraDetailsHeader {
    font-size: 13px;
    font-weight: 700;
    color: $hex-404040;
}

.itemExtraDetails {
    @extend .itemExtraDetailsHeader;
    font-weight: 500;
    // padding-left: 12px;
}

.processTime {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $hex-dadada;
    border-radius: $border-radius;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: $hex-ec3b50;
        color: $hex-ffffff;
    }
}

.bookingStatus {
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $hex-dadada;
    border-radius: $border-radius;
    overflow: hidden;
    padding: 1rem;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: $hex-ec3b50;
        color: $hex-ffffff;
    }
}

.emptybox {
    border: 1px solid $hex-dadada;
    background-color: $hex-ffffff;
    border-radius: $border-radius;
    margin-bottom: 10px;
    width: 140px;
    height: 140px;
    text-align: center;
    padding: 10px
}

.customerName {
    color: $hex-404040;
    font-weight: 600;
    font-size: 14px;
}

.customerDetails {
    color: $hex-404040;
    font-weight: 500;
    font-size: 13px;
}

.email {
    @extend .customerDetails;
    margin-top: 16px;
}

.priceLabel {
    font-size: 13px;
    font-weight: 700;
    color: $hex-404040;
}

.priceValue {
    font-size: 13px;
    font-weight: 500;
    color: $hex-404040;
    padding-left: 12px;
    text-transform: capitalize;
}

.billAmountLabel {
    font-size: 14px;
    font-weight: 700;
    color: $hex-404040;
}

.billAmount {
    font-size: 14px;
    font-weight: 500;
    color: $hex-404040;
    padding-left: 12px;
}

.copyClipText {
    border: 1px solid $hex-dadada;
    background-color: $hex-ffffff;
    border-radius: $border-radius;
    margin-bottom: 10px;
    width: fit-content;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
}

.copyIcon {
    text-align: end;
    cursor: pointer;
}

.resendMailContainer {
    position: relative;

    .countMail {
        top: -5px;
        right: -5px;
        border: 1px solid silver;
        border-radius: 10px;
        padding: 0px 5px 0px 5px;
        position: absolute;
        background: $hex-4ab96a;

        .countMailText {
            font-size: 12px;
            font-weight: bold;
            color: $hex-ffffff;
        }
    }
}