@import 'variables';

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $font-family;
    src: local($font-family), url('../assets/fonts/GothicA1-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}