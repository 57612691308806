@import '../module-variables';

.container {
    border: 1px solid $hex-dadada;
    border-radius: 18px;
    overflow: hidden;
    width: 100%;
    max-width: 250px;
    height: 318px;
    margin: 10px 0px 30px;

    .image {
        width: 100%;
        height: 198px;
        background: $hex-868e96;
    }

    .bottom {
        width: 100%;
        padding: 12px;
    }
}