@import '../module-variables';

.container {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 71px);

    @include media-breakpoint-down(xl) {
        &.showSearch {
            height: calc(100vh - 119px);
        }
    }
}