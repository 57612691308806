@import '../module-variables';

.button {
    border-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    outline: none;
    border: 1px solid $hex-dadada;
    box-shadow: $box-shadow-sm;

    &.small {
        height: 40px;
    }

    &.large {
        height: 55px;
    }

    &:disabled {
        cursor: no-drop;
        background: $hex-dadada;
        color: $hex-404040;
    }
}